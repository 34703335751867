import { getDefaultTheme, isDarkMode } from '../utils/functions/themeFunctions'
import logo from '../assets/img/logo.svg'
import whiteLogo from '../assets/img/whiteLogo.svg'

const LoadingPage = () => {
  const theme = getDefaultTheme()
  const darkMode = isDarkMode(theme)

  // In this page we use inline styles because if we use some classes or import any css file, it will not be displayed faster enough to show in pages loading
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: darkMode ? 'black' : '#f0f0f0',
        overflow: 'hidden',
      }}
    >
      <img alt='Img loading splash' height='60' src={darkMode ? whiteLogo : logo} />
    </div>
  )
}

export default LoadingPage
